import { defineStore } from 'pinia';

import { client } from '$common/services/http.ts';

export type FeatureFlagKey = string;
export type FeatureFlagValue = boolean;

export const useFeatureFlagsStore = defineStore('FeatureFlags', {
  state: (): {
    features: Map<FeatureFlagKey, FeatureFlagValue>;
    loading: Set<FeatureFlagKey>;
  } => {
    return {
      features: new Map(Object.entries(window.features ?? {})),
      loading: new Set(),
    };
  },

  getters: {
    keys: (state) => Array.from(state.features.keys()),

    values: (state) => Array.from(state.features.values()),

    exists: (state) => (name: FeatureFlagKey) => state.features.has(name),

    isLoading: (state) => (name: FeatureFlagKey) => state.loading.has(name),

    anyLoading: (state) => state.loading.size > 0,

    status: (state) => (name: FeatureFlagKey, fallback?: FeatureFlagValue) =>
      state.features.get(name) ?? fallback,

    hasAny: (state) => (names: FeatureFlagKey[], fallback?: FeatureFlagValue) =>
      names.some((name) => state.features.get(name) ?? fallback),

    hasEvery: (state) => (names: FeatureFlagKey[], fallback?: FeatureFlagValue) =>
      names.every((name) => state.features.get(name) ?? fallback),
  },

  actions: {
    async askForOne(feature: FeatureFlagKey, force = false) {
      if (!force && (this.isLoading(feature) || this.exists(feature))) {
        return this.status(feature);
      }

      try {
        this.loading.add(feature);

        const { data } = await client.get<Record<FeatureFlagKey, FeatureFlagValue>>(
          `/api/features/${feature}`,
        );

        this.features.set(feature, data[feature]);
      } finally {
        this.loading.delete(feature);
      }

      return this.status(feature);
    },

    async askForMany(features: FeatureFlagKey[], force = false) {
      return Promise.allSettled(
        features.flatMap((feature: string) => this.askForOne(feature, force)),
      );
    },

    async refresh() {
      if (this.features.size === 0) {
        return Promise.resolve([]);
      }

      return Promise.allSettled(this.keys.map((feature) => this.askForOne(feature, true)));
    },
  },
});
